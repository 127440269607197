import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(filtros, "canalesCaptacion"),

  // POST
  activar: (data) =>
    RepoUtils.ejecutarPeticionPostV2(data, "canalesCaptacion", "activar"),
  desactivar: (data) =>
    RepoUtils.ejecutarPeticionPostV2(data, "canalesCaptacion", "desactivar"),
};
