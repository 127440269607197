
import CanalCaptacionService from "../../../Services/CanalCaptacionService";
export default {
  name: "canal-captacion-gestor",
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Configuración" },
        { etiqueta: "Canales de captación" },
      ],
      statusOpciones: [
        this.constantesStatusCadena.canalCaptacionActivo,
        this.constantesStatusCadena.canalCaptacionInactivo,
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      canales: [],
      columnas: [
        {
          name: "nombre",
          header: "Nombre",
          align: "left",
          field: "titulo",
          minWidth: 160,
          width: 350,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 150,
          width: 200,
          templateName: "statusCadena",
          statusName: "status",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 100,
          width: 120,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 140,
          width: 200,
        },
        {
          name: "opciones",
          header: "",
          align: "center",
          minWidth: 70,
          width: 70,
          columnChooser: false,
          templateName: "opciones",
          templateOpciones: [
            {
              accion: "activar",
              icono: "confirmar",
              tooltipTexto: "Activar canal de captacion",
              restriccionCampo: "status",
              restriccionValores: [
                this.constantesStatusCadena.canalCaptacionActivo,
              ],
            },
            {
              accion: "desactivar",
              icono: "quitar",
              tooltipTexto: "Desactivar canal de captación",
              restriccionCampo: "status",
              restriccionValores: [
                this.constantesStatusCadena.canalCaptacionInactivo,
              ],
            },
          ],
        },
      ],
      accionLimpiar: false,
      editarCanalCaptacionObj: {},
      modalActivar: false,
      modalDesactivar: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerCanalesCaptacion(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s), // El segundo parametro es para arrays con valores numericos
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerCanalesCaptacion(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await CanalCaptacionService.listarGestor(this.filtros)
        .then((resp) => {
          let canales = resp;
          this.canales = canales.map((p) => {
            return {
              ...p,
              registro_fecha: new Date(p.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async activarCanalCaptacion(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        canalCaptacionId: this.editarCanalCaptacionObj.canal_captacion_id,
      };
      await CanalCaptacionService.activar(datos)
        .then(async (resp) => {
          this.cerrarModalActivar();
          await this.obtenerCanalesCaptacion(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async desactivarCanalCaptacion(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        canalCaptacionId: this.editarCanalCaptacionObj.canal_captacion_id,
      };
      await CanalCaptacionService.desactivar(datos)
        .then(async (resp) => {
          this.cerrarModalDesactivar();
          await this.obtenerCanalesCaptacion(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
      };
      this.obtenerCanalesCaptacion();
      this.accionLimpiar = true;
    },
    manejarClickOpcion(canalCaptacionObj, accion) {
      this.editarCanalCaptacionObj = canalCaptacionObj;
      if (accion == "activar") this.abrirModalActivar();
      if (accion == "desactivar") this.abrirModalDesactivar();
    },
    abrirModalActivar() {
      this.modalActivar = true;
    },
    cerrarModalActivar() {
      this.canalCaptacionObj = {};
      this.modalActivar = false;
    },
    abrirModalDesactivar() {
      this.modalDesactivar = true;
    },
    cerrarModalDesactivar() {
      this.canalCaptacionObj = {};
      this.modalDesactivar = false;
    },
  },
};
